export default [
  {
    text: 'constrain.table.name',
    value: 'name',
  },
  {
    text: 'constrain.table.active',
    value: 'isActive',
    type: 'boolean',
  },
];
